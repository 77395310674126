import styled from 'styled-components'
import { rem } from 'polished'
import {media} from '../../styled/themeVariables'

export const H1 = styled.h1`
  font-size: ${rem(22)};
  line-height: 1.2;
  margin: 0;
  color: ${(props) => props.theme.colors.linkDark};
  padding: ${rem(30)} 0 ${rem(50)};

  ${media.tablet`
    font-size: 2em;
 `}
`
export const H2 = styled.h2`
  margin: 0 0 ${rem(16)};
  color: ${(props) => props.theme.colors.linkDark};
  padding: ${rem(26)} 0;

  ${media.tablet`
    margin: 0 0 ${rem(0)};
 `}
`

export const H3 = styled.h3`
  margin: 0 0 ${rem(16)};
  color: ${(props) => props.theme.colors.linkDark};
  padding: ${rem(26)} 0;
  font-size: ${rem(16)};

  ${media.tablet`
    margin: 0 0 ${rem(0)};
 `}
`

export const Center = styled.div`
  text-align: center;
`

export const LI = styled.li`
  position: relative;
  padding-left: 22px;

  &::before {
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background: ${(props) => props.theme.colors.list};
    display: inline-block;
    position: absolute;
    top: 14px;
    left: 0;
  }
`

export const UL = styled.ul`
  padding: 0;
  list-style-type: none;

  ${props => props.nostyle
    ? `${LI} {
        padding-left: 0;
        &::before { content: none }
      }`
    : ''
  }
`
