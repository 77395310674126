import styled from 'styled-components'
import { rem, lighten, darken } from 'polished'
import variables, {media} from '../../styled/themeVariables';

export const Input = styled.input`
  box-sizing: border-box;
  display: block;
  border: 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.gold};
  color: ${(props) => props.theme.colors.input};
  padding: ${rem(15)} ${rem(15)} ${rem(15)};
  margin: ${rem(10)} 0 ${rem(30)};
  width: 100%;
  cursor: pointer;
  background: ${(props) => props.theme.colors.bg};


  &::placeholder {
    opacity: 0;
    color: ${(props) => props.theme.colors.brand};
  }

  &:focus {
    outline: 1px solid ${(props) => props.theme.colors.border};
  }
  padding-left: 0;
`

export const CheckboxCustom = styled.input`
  -webkit-appearance: none;
  width: auto;
  align-items: center;
`

export const CheckboxLabel = styled.label`
  border: 1px solid ${(props) => props.theme.colors.bg};
  padding: 5px 10px;
  height: 100%;
  min-height: 25px;
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.gold};
  color: white;
  cursor:pointer;
`

export const Unchecked = styled.div`
  width: 15px;
  height: 15px;
  border: 1px solid white;
  border-radius: 15px;
  margin-right: 15px;
  flex-shrink: 0;
`

export const Label = styled.label`
  display: block;
  color: ${(props) => props.theme.colors.brandOppositeDark};
  font-weight: bold;
  font-size: ${rem(18)};
`

export const Textarea = styled.textarea`
  box-sizing: border-box;
  display: block;
  border: 0;
  border-bottom: 2px solid ${(props) => props.theme.colors.gold};
  color: ${(props) => props.theme.colors.input};
  padding: ${rem(15)} ${rem(15)} ${rem(15)};
  margin: ${rem(10)} 0 ${rem(30)};
  width: 100%;
  cursor: pointer;
  background: ${(props) => props.theme.colors.bg};


  &::placeholder {
    opacity: 0;
    color: ${(props) => props.theme.colors.brand};
  }

  &:focus {
    outline: 1px solid ${(props) => props.theme.colors.border};
  }
  min-height: 150px;
  padding-left: 0;
`

export const Button = styled.button`
  text-decoration: none;
  background-color: ${(props) => props.theme.colors.button};
  padding: ${rem(25)} ${rem(35)};
  color: white;
  border: 0;
  border-radius: ${(props) => props.theme.radius.medium}px;
  cursor: pointer;
  font-size: ${rem(20)};

  :hover {
    background-color: ${darken(0.2, variables.colors.button)};
  }

  ${props => props.disabled && `
      padding: ${rem(20)} ${rem(35)};
    `
  }

  ${props => props.link && `
    background: none;
    color: ${(props) => props.theme.colors.link};
    text-decoration: underline;
    padding: 20px 0;

    :hover {
      text-decoration: none;
      background: none;
    }
  `
}
`

export const ButtonPlain = styled.button`
  text-decoration: none;
  background-color: transparent;
  padding: 0 ${rem(25)};
  border: 0;
  cursor: pointer;
  font-size: ${rem(20)};
  margin-top: -30px;

  &:hover svg {
    fill: ${(props) => props.theme.colors.brand}
  }

  ${media.tablet`
    margin-top: 0
  `}
}
`

export const ButtonReset = styled(Button)`
  background-color: ${(props) => props.theme.colors.brand};

  :hover {
    background-color: ${lighten(0.2, variables.colors.brand)};
  }
`

export const Error = styled.div`
  background-color: red;
  color: white;
  font-weight: 500;
  padding: 3px 10px;
  margin: 8px 0;
  border-radius: 5px;
`

export const Success = styled.div`
  color: #ffffff;
  font-weight: 700;
  margin: 30px 0;
  padding: 20px 30px;
  background-color: #78a978;

`

export const Loader = styled.div`
  margin: 0 20px 0 0;
  width: 20%;
  text-align: center;
  display: inline-block;
`

export const Form = styled.form`
   padding: 20px 0;

  ${media.tablet`
    padding: 50px 40px;
    border: 1px solid ${(props) => props.theme.colors.border};
    border-radius: 5px;
  `}
`
