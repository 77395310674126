import * as React from "react";
import { Flex, Box } from 'reflexbox';
import {Center, H2} from "../typography/styled/typography";
import { Translate, TranslateSwitch } from '../tr';
import {Ornament} from "../layout/ornament";

export const Testimonial = () => (
  <TranslateSwitch cs={
    <>
      <Ornament/>
      <Center>
        <H2><Translate text={'What people say'} /></H2>
      </Center>
      <Flex flexWrap={'wrap'} my={[1, 3, 3]}>
        <Box width={[1, 1, 1/3]} px={[1, 3, 3]}>
          <cite>
            <p>"Požádala jsem Páju o ztvárnění místa, které je mi na celém světě nejbližší a mám s ním spojené silné citové vzpomínky - jezera skrytého v italských horách. Ani jsem si v tu chvíli nedokázala představit, jak ideální verze takového obrazu může vypadat - dokud jsem obraz poprvé nespatřila v rámařství. </p>
            <p>Pája uchopila malbu s obrovskou vnímavostí a citem. Nejenže vystihla fakticky přesně jak místo samotné vypadá, ale zároveň do něj promítla veškeré mé pocity, které se s ním pojí. Má pro mě obrovskou hodnotu. Děkuju 	&lt;3"  </p>
            <p style={{ textAlign: "right" }}>&mdash;Lucka Štěpánková</p>
          </cite>
        </Box>
        <Box width={[1, 1, 1/3]} px={[1, 3, 3]}>
          <cite>
            <p>"První obraz od Páji, který jsem viděla mě uchvátil propracovaností a přesností barev. O pár měsíců později jsem uvažovala o nějakém zvláštním dárku a napadlo mě obrátit se s poptávkou na ní. Obraz, který jsem zadala byl naprosto podle mých představ a hlavně podle představ mého přítele, pro kterého jsem ho nechávala malovat.</p>
            <p>Pája je velmi otevřený člověk, který respektuje zadání, ale do každého díla vloží kus sebe. Každou menší změnu nebo odchylku, která by mohla obraz ozvláštnit či vylepšit od zadání, konzultuje. Je to výjimečně citlivý a skvělý člověk. Spolupráci s ní mohu po několika vlastních zkušenostech velmi doporučit."</p>
            <p style={{ textAlign: "right" }}>&mdash;Iva Frýdová</p>
          </cite>
        </Box>
        <Box width={[1, 1, 1/3]} px={[1, 3, 3]}>
          <cite>
            <p>"Když jsem se rozmýšlela, koho oslovit, aby mi namaloval obraz jako dárek, byla Pája jasnou volbou. Pro výběr předlohy se nespokojila jen tak s ledajakou fotkou, jasně instruovala, jak má předloha vypadat (jaké nasvícení, kontrasty barev, pozadí), a tu pak přenesla svým uměním na papír. Obraz naprosto předčil moje očekávání a sklidil veliký úspěch nejen u obdarovaného. Pokud hledáte malíře, který maluje srdcem a není mu jedno, jak bude finální výsledek vypadat, hledáte právě Páju."</p>
            <p style={{ textAlign: "right" }}>&mdash;Hanka Mojžíšová</p>
          </cite>
        </Box>
      </Flex>
    </>
  } en={null} />
);
