import styled from 'styled-components'
import { rem, darken } from 'polished'
import { Box } from 'reflexbox'
import { Button } from "../../forms/styled/form";
import variables, { media } from "../../styled/themeVariables";
import {Link} from "gatsby";
// import variables, { media } from '../../../components/styled/themeVariables'

const boxHeight = '400px';
const boxHeightMob = '250px';

export const Cover = styled.div`
  background-color: ${(props) => props.theme.colors.brandOppositeDark};
  position: relative;
  display: flex;
  flex-wrap: wrap;
  height: 340px;
  margin-bottom:  ${rem(10)};
  overflow: hidden;

  ${media.tablet`
    height: 500px;
    flex-wrap: nowrap;
    margin-bottom:  ${rem(30)};
 `}

  ${media.desktop`
    height: 600px;
 `}
`

export const FeaturedImg = styled.img`
  width: 100%;
  position: relative;
  top: -100px;

  ${media.tablet`
    display: block;
    top: auto;
 `}

  ${media.desktop`

 `}
`

export const FeaturedCover = styled(Box)`
  ${media.tablet`
    display: flex;
    align-items: center;
 `}
`;

export const FeaturedFull = styled.img`
  display: none;
  position: absolute;
  bottom: 14px;
  left: 10px;
  width: 200px;
  border: 10px solid ${(props) => props.theme.colors.bg};
  z-index: 2;

  ${media.tablet`
    display: block;
    bottom: 10px;
 `}
`

export const FeaturedTitle = styled.h2`
  color: ${(props) => props.theme.colors.lightText};
  padding: ${rem(10)} ${rem(50)};
  font-size: ${rem(28)};
  line-height: 2;
  letter-spacing: 1px;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  font-weight: 400;

  ${media.tablet`
    font-size: ${rem(30)};
    margin: 0;
    padding: ${rem(30)} ${rem(60)};
    font-size: ${rem(30)};

 `}

  ${media.desktop`
    font-size: ${rem(40)};
 `}
`

export const GoldBox = styled(Box)`
   display: flex;
   flex-direction: column;
   position: absolute;
   height: 100%;
   background-color: rgba(0, 0, 0, 0.3);

   ${media.tablet`
    position: static;
    height: auto;
    background-color: ${(props) => props.theme.colors.gold};
 `}
`

export const CTABox = styled(Button)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.bg};
  color: ${(props) => props.theme.colors.link};
  font-weight: normal;
  font-size: ${rem(18)};
  padding: ${rem(10)} ${rem(25)};
  border-radius: 0;
  // margin-bottom: ${rem(30)};

  &:hover {
    background-color: ${(props) => props.theme.colors.bg};
    color: ${darken(0.1, variables.colors.brandOppositeDark)};
  }

  ${media.tablet`
    padding: ${rem(20)} ${rem(45)};
    margin-bottom: ${rem(30)};
    font-size: ${rem(20)};
 `}
`;

export const HalfFeatured = styled(Box)`
  position: relative;
  overflow: hidden;
  height: ${boxHeightMob};
  margin: 0 0 ${rem(30)};

  ${media.largeMobile`
    height: ${boxHeight};
  `}

  img {
    position: absolute;
    top: 37px;
    left: -65px;
  }

  a {
    border: 1px solid ${(props) => props.theme.colors.gold};
    text-decoration: none;
    height: ${boxHeightMob};
    display: flex;
    overflow: hidden;
    align-items: flex-start;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 10;

    ${media.largeMobile`
      height: ${boxHeight};
    `}

    ${media.tablet`
      height: ${boxHeight};

      &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: ${boxHeight};
      }

      &:hover {
        &:after {
          background-color: transparent;
        }
      }

      img {
        left: auto;
      }
   `}
  }

  &:hover ${CTABox} {
    background: ${(props) => props.theme.colors.link};
    color: ${(props) => props.theme.colors.bg};
  }

  button {
    position: relative;
    z-index: 11;
  }
`;

export const CTA = styled(Button)`
  background: ${(props) => props.theme.colors.button};
  border-radius: ${(props) => props.theme.radius.big}px;
  color: ${(props) => props.theme.colors.lightText};
  font-weight: normal;
  font-size: ${rem(20)};
  padding: ${rem(20)} ${rem(55)};
  margin-bottom: ${rem(30)};
`;

export const CTATransparent = styled(Link)`
  text-decoration: none;
  border-radius: ${(props) => props.theme.radius.medium}px;
  margin: 0 10px;
  background-color: transparent;
  border: 1px solid ${(props) => props.theme.colors.bg};
  color: ${(props) => props.theme.colors.bg};
  font-weight: normal;
  font-size: ${rem(18)};
  padding: ${rem(18)} ${rem(55)};
  margin-bottom: ${rem(30)};
  align-self: center;

  &:hover {
    background-color: ${(props) => props.theme.colors.bg};
    color: ${(props) => props.theme.colors.brandOppositeDark};
  }

  ${media.tablet`
    font-size: ${rem(20)};
 `}
`;
