import * as React from 'react';
import { Flex, Box } from 'reflexbox'
import {
  Cover,
  FeaturedImg,
  FeaturedFull,
  FeaturedTitle,
  FeaturedCover,
  HalfFeatured,
  CTABox,
  CTATransparent,
  GoldBox,
} from "./styled/featured";
import {Container} from "../layout/container";
import {Center, H2, LI, UL} from "../typography/styled/typography";
import {Ornament} from "../layout/ornament";
import { Translate, TranslateImg } from '../tr';
import { LangLink } from '../lang-link';
import {Testimonial} from "../proposal/Testimonial";

const featuredImgSrc = '/static/12-cello-rise-d20918160adf7594b710788708162f68.jpg';

const Featured = () => (
  <>
    <Cover>
      <FeaturedCover width={[ 1, 1, 1, 2/3, 2/3 ]} p={0}>
        <FeaturedImg src={featuredImgSrc} alt={'Rise'} />
        <FeaturedFull src={featuredImgSrc} alt={'Rise - fullscreen'} />
      </FeaturedCover>
      <GoldBox
        width={[ 1, 1,  1, 1/3, 1/3 ]}
        p={0}
      >
        <FeaturedTitle><Translate text={'Let your ideas come alive'} /></FeaturedTitle>
        <CTATransparent to={'/contact'}>
          <Translate text={'Paint me a picture'} />
        </CTATransparent>
      </GoldBox>
    </Cover>

    <Container>
      <Ornament/>
      <Center>
        <H2><Translate text={'Browse finished dreamscapes'} /></H2>
        <cite>Velký obraz minulosti lze přenést do jemného detailu přítomnosti. &mdash;Will Gomperz</cite>
      </Center>
      <Flex flexWrap={'wrap'} mb={[3]} justifyContent={'center'} my={5}>
        <HalfFeatured width={[ 1,1,1/2 ]} px={[2,2,2,3,3]} mb={[3,3,3,4,4]}>
          <LangLink to={'/oil-gallery'}>
            <CTABox>
              <Translate text={'Nature oil collection'} />
            </CTABox>
            <TranslateImg
              src={'/preview/preview-nature.jpg'}
              alt={'Nature oil collection'}
            />
          </LangLink>

        </HalfFeatured>
        <HalfFeatured width={[ 1,1,1/2 ]} px={[2,2,2,3,3]} mb={[3,3,3,4,4]}>
          <LangLink to={'/dead-art'}>
            <CTABox>
              <Translate text={'Halloween oil collection'} />
            </CTABox>
            <TranslateImg src={'/preview/preview-horor.jpg'} alt={'Halloween oil collection'} />
          </LangLink>
        </HalfFeatured>

        <HalfFeatured width={[ 1,1,1/2 ]} px={[2,2,2,3,3]}>
          <LangLink to={'/watercolor-gallery'}>
            <CTABox>
              <Translate text={'Watercolor collection'} />
            </CTABox>
            <TranslateImg src={'/preview/preview-watercolor.jpg'} alt={'Watercolor collection'} />

          </LangLink>
        </HalfFeatured>
      </Flex>

      <Testimonial/>

      <Ornament/>
      <Center>
        <H2><Translate text={'Exhibitions'} /></H2>
        <UL nostyle>
          <LI>9/3/2022 - Společná vernisáž, Muzeum policie, Praha</LI>
          <LI>10/2021 - Open Art Fest Praha - <a href={'https://www.openartfest.cz/l/katalogu-vystavovatelu-open-art-fest-2021-ve-formatu-pdf/'} target={'_blank'}>Katalog vystavovatelů</a></LI>
          <LI>09/2021 - Lesní výstava Jablonec nad Nisou - Dobrá Voda </LI>
          <LI>1/2021 - Skupinová výstava, Galerie u Zlatého Kohouta, Praha</LI>
          <LI>11/2019 - Pivovar Bubeneč, Praha - Halloweenská výstava</LI>
          <LI>05/2019 - Festival amatérské tvorby, Galerie Kotelna, Praha</LI>
          <LI>12/2017 - Základní škola v Dobré u Frýdku-Místku</LI>
        </UL>

        <Flex justifyContent={'center'}>
          <Box width={[1, 3/5, 3/5]}>
            <cite>
              "V listopadu loňského roku jsme dostali pozvání na první výstavu obrazů. S velkým očekáváním jsme dorazili na místo a rozmanitost obrazů, která se před námi rozprostřela, naprosto přesahovala veškerá očekávání, která jsme měli. Výstava byla zaměřená na Halloween, a přesto z každého obrazu sálala naprosto odlišná energie. Čekala jsem něco strašidelného, ale mé pocity byly naprosto opačné. Byla jsem uchvácena především obrazem, na kterém se nachází duch dívky v lese. V každém díle se odráží hodiny a hodiny práce. Doma máme od Páji celkem šest obrazů a jsem si jistá, že to není konečné číslo."

              <p style={{ textAlign: "right" }}>&mdash;Iva Frýdová</p>
            </cite>
          </Box>
        </Flex>
      </Center>
    </Container>
  </>
);

export default Featured;
