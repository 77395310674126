import * as React from "react";
import Layout from "../components/layout";
import Featured from "../components/featured/Featured";
import SEO from "../components/seo";
import { Lang } from "../components/tr";

const Home = ({ pageContext: { lang = Lang.cs }, location }) => (
  <Layout lang={lang} location={location}>
    <SEO lang={lang} />
    <Featured />
  </Layout>
);

export default Home;
