import styled from 'styled-components'
import {withPrefix} from "gatsby";
import {media} from "../styled/themeVariables";

const ornament = withPrefix('/ornament.png');

export const Ornament = styled.div`
  background-image: url(${ornament});
  background-position: center bottom;
  background-repeat: no-repeat;
  padding-bottom: 80px;
  opacity: 0.8;

  ${media.tablet`
    padding-bottom: 60px;
  `}
`;
